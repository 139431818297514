import React, { useEffect, useState } from 'react'
import Header from '../../../components/Header';
import categoriesBannerIMG from "../../../assets/images/categoriesBannerIMG.png";
import emptyCartIMG from "../../../assets/images/emptyCartIMG.png";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Footer from '../../../components/Footer';
import { Pagination, Row, Col, Spin, Input } from 'antd';
import ProductCard from '../../../components/ProductCard';
import { getDataByCategory, getSubProduct } from '../../../store/services/category';
import { MdSearch } from 'react-icons/md';
import { FaArrowCircleLeft } from 'react-icons/fa';

const Category = () => {
  const [searchproducts, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 8;
  const [filterCategory, setFilterCategory] = useState([]);
  const [filterdData, setFilterdData] = useState<any>([]);
  const [filteredResults, setFilteredResults] = useState<any>([]); // State for search results
  const [totalFilteredCount, setTotalFilteredCount] = useState(0); // Total count of filtered results

  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const getData = async () => {
    setLoading(true);
    try {
      if (params.id) {
        const res: any = await getDataByCategory({ id: params.id });
        const subCategories = res.data.data
          .map((product: any) => product.subCategory)
          .reduce((acc: any, subCategory: any) => {
            if (!acc.find((item: any) => item._id === subCategory._id)) {
              acc.push(subCategory);
            }
            return acc;
          }, []);
        if (subCategories.length > 0) {
          filter(subCategories[0]._id);
          setId(subCategories[0]._id);
        }
        setFilterCategory(subCategories);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  }




  const filter = async (e?: any, mainPage?: number) => {

    setLoading(true);
    try {
      if (e) {
        const res = await getSubProduct(e, currentPage, perPage, searchproducts);
        if(res?.data?.products){
          setFilterdData(res?.data?.products);
          setFilteredResults(res?.data?.products); // Set initial results
          setTotalFilteredCount(res?.data?.totalCount);
          setLoading(false);
        }
       
      } else {
        if (id) {
          const res = await getSubProduct(id, currentPage, mainPage ? mainPage : perPage, searchproducts);
          if(res?.data?.products){
            setFilterdData(res?.data?.products);
            setFilteredResults(res?.data?.products); // Set initial results
            setTotalFilteredCount(res?.data?.totalCount);  
            setLoading(false);
          }
        }
      }
    } catch (error) {
      console.log("error", error);
    }
    finally {
    }
  }


  useEffect(() => {
    if (params.id) {
      getData();
    }
  }, [params]);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  useEffect(() => {
    filter();
  }, [currentPage, id, searchproducts])

  useEffect(() => {
    if (searchproducts) {
      setFilteredResults(
        filterdData.filter((product: any) =>
          product.name.toLowerCase().includes(searchproducts.toLowerCase())
        )
      );
      setTotalFilteredCount(
        filterdData.filter((product: any) =>
          product.name.toLowerCase().includes(searchproducts.toLowerCase())
      ).length
    );
    } else {
      setFilteredResults(filterdData);
    }
  }, [searchproducts, filterdData]);
    
  return (
    <div className="Product">
      <Header />
      <div className="layoutPaddingInline">
        <div className='back_to' onClick={() => { navigate(-1) }}>
          <FaArrowCircleLeft color='var(--mainBlue)' fontSize={34} />
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            Back
          </div>
        </div>
        <div className='Banner'>
          <img src={categoriesBannerIMG} alt='categoriesBannerIMG' />
          <p style={{ color: "white" }}>{location?.state?.title ?? "Category Products"}</p>
        </div>
      </div>
      <div className='product_search_input layoutPaddingInline'>
        <Input 
          placeholder="Search products..."
          value={searchproducts}
          onChange={(e) => setSearch(e.target.value)}
          prefix={<MdSearch size={20} />}
        />
      </div>
      <div className='btn_Category_main'>
        {
          filterCategory.map((item: any) => {
            return (
              <div key={item._id} onClick={() => { filter(item._id,1); setCurrentPage(1); setId(item._id) }} className={`btn_Category ${item._id === id && "btn_Category_active"}`}>{item?.name}</div>
            )
          })
        }
      </div>
      {loading ? (
        <div className="NoHistory" style={{ height: "50vh" }}>
          <Spin size="large" style={{ paddingTop: "40px" }} />
        </div>
      ) : filteredResults?.length > 0 ? (
        <>
          <div className="layoutPaddingInline customPadding">
            <Row gutter={[20, 20]}>
             {/* filterdData?.slice((currentPage - 1) * perPage, currentPage * perPage).map(...) */}
              {filterdData?.map((v: any, i: number) => (
                <Col key={i} xs={24} sm={12} md={8} lg={8} xl={6} xxl={6}>
                  <ProductCard data={v} />
                </Col>
              ))}
            </Row>
          </div>
        </>
      ) : (
        <div className="emptyProduct">
          <img width={"40%"} src={emptyCartIMG} alt="emptyCart" />
          <p className="emptyProductText">No Product found.</p>
        </div>
      )}

      <div className="product_pagination_box">
        <Pagination
          current={currentPage}
          total={totalFilteredCount}
          pageSize={perPage}
          onChange={(page) => setCurrentPage(page)}
          showSizeChanger={false}
        />
      </div>
      <Footer />
    </div>

  )
}

export default Category;