import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getToken } from "../utils";

const PublicWrapper: React.FC = () => {
   const isAuthenticated = getToken();
  if (isAuthenticated) {
     return <Outlet />;
   } else {
     return <Navigate to="/login" />;
   }
};

export default PublicWrapper;
