import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface ProductHeaderType {
  title?: string;
  description?: string;
  children?: React.ReactNode;
  products?: any[];
}

const ProductHeader: React.FC<ProductHeaderType> = ({
  title,
  description,
  children,
  products = []
}) => {
  const navigate = useNavigate();
  const [category, setCategory] = useState<any[]>([]);


  useEffect(() => {
    if (products.length > 0) {
      setCategory(products[0]);
    }
  }, [products]);


  return (
    <>
      <div className="product_header">
        <div className="ph_left">
          <h2>{title}</h2>
          {description && <p>{description}</p>}
        </div>

        <div
          className="ph_right"
          // @ts-ignore
          // onClick={() => navigate(`/category/${category.category._id}`, { state: { title: title } })}
          onClick={() => navigate('/products')}
        >
          {
            products.length > 0 && <p className="gradientText">View All</p>

          }
          {/* <div className="arrow_left">
            <GoChevronLeft />
          </div>
          <div className="arrow_right">
            <GoChevronRight />
          </div> */}
        </div>
      </div>
      {children && children}
    </>
  );
};

export default ProductHeader;
