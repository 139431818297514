import { useEffect, useState } from "react";
import "react-multi-carousel/lib/styles.css";
import { Row, Col, Spin } from "antd";
import { IoIosArrowRoundBack, IoIosArrowRoundForward } from "react-icons/io";
import { useAppSelector } from "../../store/hooks";
import emptyCartIMG from "../../assets/images/emptyCartIMG.png";
import { getReviews } from "../../store/services/review";
import GroupWishlistCard from "../GroupWishlistCard";
import Loader from "../Loader";
import { getGroupWishlist } from "../../store/services/groupWishlist";

const CustomerReview = () => {
  const { reviews } = useAppSelector((state) => state.review);
  const [wishlistSlice, setWishlistSlice] = useState(4);
  const { search } = useAppSelector((state) => state.products);
  const [allWishlist, setAllWishlist] = useState([]);
  const { groupWishlist } = useAppSelector((state) => state.wishlist);
  const { user } = useAppSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if(user){
      getData();
    }
  }, []);


  const getData = async () => {
    try {
      setLoading(true);
      await getGroupWishlist();
    } catch (error) {
      console.error("Failed to get wishlist:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const filterd = groupWishlist?.filter(
      (v) =>
        v?.status === "approved" &&
        v?.product?.productId?.name
          .toLowerCase()
          .includes(search.toLowerCase().trim())
    );
    console.log("Filtered Wishlist:", filterd); // Debugging statement
    setAllWishlist(filterd);
  }, [groupWishlist, search]);

  return (
    <div className="customer_review_container_main">
      <img
        src={require("../../assets/images/review-left.png")}
        className="left_img"
        alt="review"
      />
      <img
        src={require("../../assets/images/review-right.png")}
        className="right_img"
        alt="review"
      />
      <div className="customer_review_container">
        <h1>Group Wishlist</h1>
        {loading ? (
          <div className="empty_cart">
            <Spin />
          </div>
        ) : allWishlist.length > 0 ? (
          <div className="layoutPaddingInline customPadding" >
            <div className="main_card">
              {allWishlist.map((v, i) => (
                <div style={{width:"320px",height:"450px"}}>
                  <GroupWishlistCard data={v} />
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div className="emptyCart" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <img width={"40%"} src={emptyCartIMG} alt="emptyCart" />
              <p className="emptyCartText">
                There are no group wishlist yet.
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomerReview;
