import React, { useState, useEffect } from "react";
import { Badge, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { FaBell } from "react-icons/fa6";
import { NotificationStatusIcon } from "../../config/NotificationConstants";
import { getAllNotifications, getNotificationUnreadCount } from "../../store/services/notification";
import { useAppSelector } from "../../store/hooks";

const NotificationDropdown: React.FC = () => {

  const [windowSize, setWindowSize] = useState<boolean>(false);
  const navigate = useNavigate();
  const { notificationUnreadCount, notifications } = useAppSelector((state) => state.notifications);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const payload = { 
      page: 1,
      limit: 5,
    };
    await getNotificationUnreadCount();
    await getAllNotifications(payload);
  };

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth < 500);
    };

    handleWindowResize(); // Set initial value
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const Item = ({ name, icon }: { name: string; icon: any }) => (
    <div className="notification_dropdown_item">
      <div>{icon}</div>
      <p>{name}</p>
    </div>
  );

  const items = notifications?.map((notification: any, index: number) => ({
    key: index.toString(),
    label: (
      <Item
        name={notification?.message}
        icon={
          // @ts-ignore
          NotificationStatusIcon[notification?.type]
        }
      />
    ),
  })) || [];

  const menu = (
    <div style={{ background: "white", padding: "20px", boxShadow: "0 6px 16px 0 rgba(0, 0, 0, 0.08)", borderRadius: '10px' }}>
      {items.length > 0 ? (
        items.map((item: any) => (
          <div key={item.key}>{item.label}</div>
        ))
      ) : (
        <div className="notification_dropdown_item">
          No notifications
        </div>
      )}
      <div className="notification_dropdown_item">
        <Button
          type="link"
          onClick={() => navigate("/settings?active=notifications")}
        >
          See All
        </Button>
      </div>
    </div>
  );

  return (
    <div
      onClick={() => navigate("/settings?active=notifications")}
      style={{
        display: "flex",
        flexDirection: 'row',
        alignItems: "center",
        gap: "12px",
        cursor: "pointer"
      }}
    >
      <Badge size="default" count={notificationUnreadCount}>
        <FaBell size={22} />
      </Badge>
    </div>
  );
};

export default NotificationDropdown;
